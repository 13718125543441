import React from 'react';
import {FacebookShareButton,
        PinterestShareButton,
        TelegramShareButton,
        LinkedinShareButton,
        TwitterShareButton} from "react-share";
import {FacebookIcon,
        PinterestIcon,
        TelegramIcon,
        LinkedinIcon,
        TwitterIcon} from "react-share";

function Footer1() {
    return (
        <>
        <footer id="footer">
            <div  className="pad-l-45 pad-r-45">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">About</h4>
                                    </article>
                                </div>
                                <p>Yiksi is a technology company that offers fintech solutions to our clients, ranging from mobile money integrations to comprehensive fintech services. We also have an internal fintech platform solution for clients who want to utilize our technology. Our goal is to provide access to global financial systems through our technology and platforms.</p>
                            </article>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">Information</h4>
                                    </article>
                                </div>
                                <ul className="list-unstyled">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/">About</a></li>
                                    <li><a href="/">Services</a></li>
                                    <li><a href="/">Works</a></li>
                                    <li><a href="/">Contact</a></li>
                                </ul>
                            </article>
                        </div>
                    </div>
                    <div className="h25"></div>
                    <div className="footer-bottom">
                        <div className="row">

                            <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                                {/* <ul className="footer-icon list-unstyled">
                                    <li>
                                        <FacebookShareButton url="https://youtu.be/3aSb1WPK4Kg" 
                                        quote= "Anema" hashtag="#anema">
                                            <FacebookIcon round={true} size={32}></FacebookIcon>
                                        </FacebookShareButton>
                                        
                                    </li>
                                    <li>
                                        <PinterestShareButton url="https://youtu.be/3aSb1WPK4Kg" media="https://youtu.be/3aSb1WPK4Kg">
                                            <PinterestIcon round={true} size={32}></PinterestIcon>
                                        </PinterestShareButton>    
                                    </li>  
                                    <li>
                                        <LinkedinShareButton url="https://youtu.be/3aSb1WPK4Kg" >
                                            <LinkedinIcon round={true} size={32}></LinkedinIcon>
                                        </LinkedinShareButton>    
                                    </li>
                                    <li>
                                        <TwitterShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                            <TwitterIcon round={true} size={32}></TwitterIcon>
                                        </TwitterShareButton>    
                                    </li>
                                    <li>
                                        <TelegramShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                            <TelegramIcon round={true} size={32}></TelegramIcon>
                                        </TelegramShareButton>    
                                    </li>
                                </ul> */}
                            </div>
                            <div className="col-md-4 col-xs-12 col-sm-4">
                                <div className="footer-logo">
                                    <img width="134px" height="50px" src="img/yiksi-logo1.svg" alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            

        </footer>
        </>
    )
}

export default Footer1
