import React from 'react'
import bg_vide from '../../videos/bigway.mp4'
function About1() {
    
    return (
        <>
            <section id="about" className="bg-non-fixed">
            {/* <video autoPlay loop muted className="bg_video">
                <source src={bg_vide} type="video/mp4"></source>
            </video> */}
            <div className="bg-inner-dark"></div>
            <div className="bg-50-r"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About</h1>
                                    </article>
                                </div>
                            </div> 

                        </div> 
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="pad-l-45 pad-r-45 block">

                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Our Vision</h4>
                                <p>
                                    Our vision is to become the most trusted and reliable fintech platform in East Africa and Africa, empowering individuals and businesses across the region. We aim to be the most impactful fintech company in the region, providing accessible and efficient financial solutions that transcend geographical boundaries.
                                </p>
                            </div>
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Our Mission</h4>
                                <p>
                                    Our mission is to empower our clients by providing the technology and platforms they need to succeed in fintech, while ensuring a secure and transparent experience for all.
                                </p>
                            </div>
                            
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                
                                <h4 className="widget-title">Our Values</h4>
                                <p>
                                    <b>Commitment to customer service - </b>We provide exceptional customer support throughout their journey with us, ensuring a seamless and enjoyable experience from initial engagement to ongoing use of our services.
                                </p>
                                <p>
                                    <b>Integrity - </b>We operate with integrity, honesty, and transparency, consistently demonstrating accountability and reliability. This enables us to build strong, lasting relationships with our customers and users, based on mutual trust and respect.
                                </p>
                                <p>
                                    <b>Inclusivity - </b>We aim to foster a strong sense of community among our team members, which leads to increased collaboration, enhanced creativity, and improved outcomes. This shared sense of purpose drives greater productivity, more effective decision-making, and encourages innovative thinking.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About1
