import React from 'react'

function Welcome1() {
    return (
        <section>
            <div className=" color-scheme-2 ">
                <div className="bg-50-l not-before"></div>
                <div className="bg_object1 lax lax_preset_fadeIn:50:100 lax_preset_spin:4000:270  lax_preset_slideY:2500:700"></div>
                <div className="container-fluid">
                    <div className="row  justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="pad-l-45 pad-r-45">
                                <div className="section-block-title">
                                    <div className="section-title">   
                                        <article className="section-title-body">
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Welcome</h1>
                                            <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                            <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                                Yiksi is a technology company that offers fintech solutions to our clients, ranging from mobile money integrations to comprehensive fintech services. We also have an internal fintech platform solution for clients who want to utilize our technology. Our goal is to provide access to global financial systems through our technology and platforms.
                                            </p>
                                        </article>
                                    </div>
                                </div>  

                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome1
