import React from 'react'
import ContactForm from './../Contact/ContactForm'
import {FacebookShareButton,
    PinterestShareButton,
    TelegramShareButton,
    LinkedinShareButton,
    TwitterShareButton} from "react-share";
import {FacebookIcon,
    PinterestIcon,
    TelegramIcon,
    LinkedinIcon,
    TwitterIcon} from "react-share";
function Contact1() {
    return (
        <>
        <section  id="contact" className="bg-non-fixed">
            <div className="bg-inner-dark2"></div>
            <div className="bg-50-l"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12  order-md-1 justify-content-center d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Contact</h1> 
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="pad-l-45 pad-r-45 block">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="widget-block mobile-center">
                                        <h4 className="widget-title"><i className="ion-android-pin"></i>Address:</h4>
                                        <p>Bruce house 10th Floor<br/> Standard street, Nairobi - Kenya</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="widget-block mobile-center">
                                        <h4 className="widget-title"><i className="ion-android-call"></i>Phone:</h4>
                                        <p>+25716435610<br/>
                                            abdikalif@gmail.com
                                        </p> 
                                    </div>
                                </div>
                            </div>
                            <ContactForm/>
                        </div>
                    </div>

                </div>
            </div>
        </section>  
        </>
    )
}

export default Contact1
