import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-pie-outline',
        title:'Fintech Solutions',
        text:'We provide cutting-edge fintech solutions that enable businesses to streamline their financial operations, improve customer experience, and increase revenue through innovative technology.'
    },
    {
        _id:'002',
        classIcon:'ion-android-phone-portrait',
        title:'Mobile Money Integrations',
        text:'Our mobile money integrations service connects businesses with leading mobile money platforms, allowing them to tap into the vast market of mobile money users and facilitate seamless transactions.'
    },
    {
        _id:'003',
        classIcon:'ion-code',
        title:'Backend Development',
        text:'With our expertise in backend development, we build robust and scalable systems that power your fintech solutions, ensuring data security, reliability, and efficient processing of financial transactions.'
    },
    {
        _id:'004',
        classIcon:'ion-android-watch',
        title:'Frontend Development',
        text:'Our frontend development services craft user-friendly and intuitive interfaces for your fintech applications, providing a seamless customer experience and setting you apart from the competition.'
    }
]
function Service1() {
    return (
        <>
          <section  id="services"  className="bg-non-fixed">
            <div className="bg-inner-dark2"></div>
            <div className="bg-50-l"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12 order-md-1 justify-content-center d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Services</h1>
                                    </article>
                                </div>  
                            </div>     
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="pad-l-45 pad-r-45 block">
                            <div className="row">
                                {services.map((service)=>(
                                    <div className="col-lg-12 col-xxl-6" key={service._id}>
                                    <div className="block-feature">
                                        <i className={service.classIcon}></i>
                                        <a href="/">{service.title}</a>
                                        <p>
                                        {service.text}
                                        </p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>  
        </>
    )
}

export default Service1
