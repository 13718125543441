import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

function Lightbox() {
    return (
        <>
        <SimpleReactLightbox>
            <SRLWrapper>
            <div className="row  gx-5">
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/yiksi/portfolio-lightbox/fintech-lightbox.webp">
                            <img  className="img-fluid" alt="Fintech" src="img/yiksi/portfolio-lightbox/fintech-lightbox.webp"/>
                        </a>
                        <span className="single-portfolio-title">Fintech</span>
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/yiksi/portfolio-lightbox/mobile-money-lightbox.webp">
                            <img  className="img-fluid" alt="Mobile Money Integration" src="img/yiksi/portfolio-lightbox/mobile-money-lightbox.webp"/>
                        </a>
                        <span className="single-portfolio-title">Mobile Money Integration</span>
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/yiksi/portfolio-lightbox/backend-development-lightbox.webp">
                            <img  className="img-fluid" alt="Backend Development" src="img/yiksi/portfolio-lightbox/backend-development-lightbox.webp"/>
                        </a>
                        <span className="single-portfolio-title">Backend Development</span>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/yiksi/portfolio-lightbox/frontend-development-lightbox.webp">
                            <img  className="img-fluid" alt="Frontend Development" src="img/yiksi/portfolio-lightbox/frontend-development-lightbox.webp"/>
                        </a>
                        <span className="single-portfolio-title">Frontend Development</span>
                    </div>
                </div> 
            </div>
            </SRLWrapper>
        </SimpleReactLightbox> 
        </>
    )
}

export default Lightbox
