import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">
         <iframe title="My Address" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7977.626788443042!2d36.820479!3d-1.285989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11707c3392f3%3A0x99b72b16ce59c61a!2sBruce%20House!5e0!3m2!1sen!2ske!4v1714521454030!5m2!1sen!2ske"></iframe>
         </div>   
        </>
    )
}

export default  Map
